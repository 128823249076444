/* Custom Post-Template with gradient
/* ---------------------------------------------------------- */
.post-template .post .no-image {
  z-index: 10;
}
.post-template .post .no-image .inner{
  padding: 5vh 0;
  max-width: 100%;
  z-index: 11;
}
.post-full-content{
  z-index: 11;
}
.post-template .site-header-background{
  background-position: top;
}
.post-template .site-header-background:before{
  background: none;
}
.post-background{
  max-width: 50%;
  margin: auto;
}
.post-background.responsive-header-img .inner{
  max-width: 50%;
}
@media (max-width: 650px){
.post-background{
    max-width: 80%;
  }
}
.post-background.responsive-header-img{
  max-width: 100%;
  height: 30vh;
  width: 100%;
  z-index: 0;
}
.post-template .post .responsive-header-img .inner{
  height: 60rem;
  z-index: 0;
}
.post-template .site-header-background:after{
  height: 30vh;
  z-index: 0;
  background: linear-gradient(rgba(252,255,255,0), rgba(21,23,26,1));
}
.post-template  .site-header-background:before{
  z-index: 0;
}

/* Custom Index-Template
/* ---------------------------------------------------------- */
.textbox-right {
  color: white;
  text-align: right;
  margin: 15vh 35vw 0 auto;
}
.textbox-right h1 {
  font-size: 2em;
  position: relative;
}
.textbox-right a {
  color: white;
  text-decoration: none;
}

.textbox-right a:hover{
  visibility: visible;
  transform: scaleX(1);
}
.textbox-right p{
  text-align: left;
  color: #8d9a98;
  width: auto;
  margin-right: -20vw;
  margin-left: 55vw;
}
.colored-box{
  background-color: #8d9a98;
  position: sticky;
  position: -webkit-sticky;
  left: 0;
  bottom: 45%;
  height: 50vh;
  width: 45%;
  z-index: -2;
}
.keyboard{
  position: sticky;
  position: -webkit-sticky;
  left: 25%;
  bottom: 20%;
  height: 75vh;
  width: 50%;
  z-index: -1;
  background-image: url(../Tastatur.png);
  background-repeat: no-repeat;
  background-size: contain;
}

@media (max-width: 1170px) {
  /*Menu has 60% of vw too*/
    .colored-box{
    width: 60vw;
    }
    .textbox-right p{
      color: white;
      margin-left: 50vw;
    }
}
@media (max-width: 750px){
  /* Smartphone*/
  .textbox-right p{
    display: none;
  }
  .textbox-right{
    max-width: 90vw;
    margin: 20vh auto;
  }
  .textbox-right h1{
      margin-bottom: 3rem;
  }
  .colored-box{
    height: 40vh;
    bottom: 15%;
  }
  .site-footer{
    background-color: #30303b;
  }
}
@media (max-width: 500px){
  .textbox-right h1{
    font-size: 1.5em;
  }

}

/* Custom Page Template with tag listing
/* ---------------------------------------------------------- */
.post-full-header .vertical{
  writing-mode: vertical-rl;
  position: absolute;
}
.site-page-and-tag .post-content, .site-page-and-tag .post-feed {
  max-width: 55vw;
  margin: 0 auto auto 0;
}
.site-page-and-tag h1,
.site-page-and-tag h2,
.site-page-and-tag h3,
.site-page-and-tag h4,
.site-page-and-tag h5,
.site-page-and-tag h6 {
  font-family: Consolas, monospace;
  color: #8d9a98;
}
.site-page-and-tag, .post-template .post-content{
  font-family: -apple-system, "Calibri light", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

/* Custom Page pageAndProducts: Softwareentwicklung and Systemadministration
/* ---------------------------------------------------------- */

.page-softwareentwicklung, .page-systemadministration {
  color: white;
}
.page-softwareentwicklung.page-template .site-main
.page-systemadministration.page-template .site-main{
  padding-bottom: 0;
}
.page-systemadministration .post-full-header .post-full-title{
  writing-mode: vertical-lr;
  float: left;
  min-width: 0;
  margin: 0 2rem;
}
.page-softwareentwicklung .post-content-right,
.page-systemadministration .post-content-right {
  width: 55%;
  float: right;
  padding-right: 15%;
}

.page-softwareentwicklung .post-content-left,
.page-systemadministration .post-content-left{
  width: 45%;
  text-align: right;
  float: left;
  margin-top: 15em;

}
.page-softwareentwicklung .post-content-left .post-feed,
.page-systemadministration .post-content-left .post-feed{
  margin-left: 8vw;
  max-width: 20vw;
}

.page-softwareentwicklung .post-content-left .post-card-title,
.page-systemadministration .post-content-left .post-card-title {
  font-size: 1.0em;
}
.page-softwareentwicklung .post-content-left img,
.page-systemadministration .post-content-left img {
display: none;
}
.page-softwareentwicklung .post-card.post,
.page-systemadministration .post-card.post {
    max-width: 300px;
}

@media (min-width: 1920px){
  .page-softwareentwicklung .post-content-left .post-feed,
  .page-systemadministration .post-content-left .post-feed{
    margin-left: 0;
    max-width: 20vw;
  }
}

@media (max-width: 1100px){
  .post-full-content h1{
    font-size: 4.0rem;
  }
}

@media (max-width: 1000px){
  .page-softwareentwicklung .post-content-right,
  .page-systemadministration .post-content-right {
    float: none;
    width: 100%;
    margin: 0 10vw;
  }

  .page-softwareentwicklung .post-content-left,
  .page-systemadministration .post-content-left {
    float: right;
    margin-right: 10vw;
    margin-top: 5em;
  }

  .page-softwareentwicklung .post-content-left .post-feed,
  .page-systemadministration .post-content-left .post-feed{
    max-width: 60vw;
  }
}

@media (max-width: 600px){
  .page-softwareentwicklung .post-content-right .post-content,
  .page-systemadministration .post-content-right .post-content{
    max-width: 100vw;
  }
  .post-full-content h1{
    font-size: 2.0rem;
  }
  .page-softwareentwicklung .post-content-left .post-feed,
  .page-systemadministration .post-content-left .post-feed{
        max-width: 100vw;
  }
}

/* normal Pages */
/* ---------------------------------------------------------- */
.normal-page .inner {
  max-width: 55%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.normal-page .post-full-content a {
      color: #8d9a98;
      font-weight: bold;
  }

@media (max-width: 1400px){
  .normal-page .inner {
    max-width: 80%;
  }
}
@media (max-width: 800px){
  .normal-page .inner {
    max-width: 90%;
  }
}


/* Custom Page pageAndProducts: Digitalisierung (post preview with little icon)
/* ---------------------------------------------------------- */
.page-digitalisierung {
  color: white;
}
@media (min-width: 1920px){
  .page-digitalisierung #site-main .inner{
    max-width: 100%;
  }
}
.page-digitalisierung .post-content{
  float: left;
  width: 25vw;
}
/*padinng of whole page ot just the left content*/
.page-digitalisierung .post-content-right{
  padding-top: 10rem;
}

/* Styling left part*/
.page-digitalisierung .post-full-header .post-full-title{
  writing-mode: vertical-lr;
  float: left;
  min-width: 0;
  margin: 2rem 3% 0 1%;
}
.page-digitalisierung .post-content-right .post-content{
  padding-top: 4rem;
}

/* Styling right part*/
/* content of postfeed needs 45 vw.
max-width above 45vw results in more space between image and text */
.page-digitalisierung .post-content-left .post-feed {
  flex-direction: row;
  text-align: right;
  max-width: 46vw;
}
.page-digitalisierung .post-content-left .post-feed .post-card{
  min-width: 100%;
  float: left;
}
.page-digitalisierung .post-content-left .post-feed h2{
  font-size: 2.1rem;
}
/* margin places text*/
.page-digitalisierung .post-content-left .post-feed .post-card.no-image .post-card-content,
.page-digitalisierung .post-content-left .post-feed .post-card-image-content{
  max-width: 20vw;
   margin-left: 15vw;
}
.page-digitalisierung .post-content-left .post-feed .post-card-image-container{
  width: 10vw;
  float: right;
}
.page-digitalisierung .post-content-left .post-feed .post-card-image {
  width: auto;
  max-height: 6em;
  object-fit: contain;
  margin: 0 auto 0 0;
}

@media (max-width: 1000px){
  .page-digitalisierung .post-content-right .post-content{
    float: none;
    max-width: 100%;
    width: 80%;
    padding: 0 0 4rem 0;
  }
  .page-digitalisierung .post-full-header .post-full-title{
    margin: 0.5rem 3% 0 1%;
    font-size: 3rem;
  }
  .page-digitalisierung .post-content-left .post-feed{
    max-width: 80vw;
  }
  .page-digitalisierung .post-content-left .post-feed .post-card-image-container{
    width: 30vw;
    max-width: 25vw;
  }
  .page-digitalisierung .post-content-left .post-feed .post-card-image-content, .page-digitalisierung .post-content-left .post-feed .post-card.no-image .post-card-content{
    max-width: 38vw;
    width: 40vw;
  }
}

/* custom footer */
/* ---------------------------------------------------------- */

.site-nav-s {
  width: 80vw;
  height: 3em;
  padding-bottom: 5em;
  margin: auto;
  justify-content: flex-end;
}
.site-nav-s ul a {
padding: 12px 24px 12px 0;
}
.site-nav-s .nav{
  margin: 0;
}

@media (max-width: 750px) {
  .site-nav-s {
    height: auto;
  }
  .site-nav-s .nav {
      flex-direction: column;
  }
  .site-nav-s ul a {
  padding: 0;
  }
  .site-footer-content {
    align-items: flex-end;
    flex-direction: column;
  }
}
.read-next-feed .post{
  padding-right: 2em;
}
.read-next-feed  .post-card-image-link{
  height: 100%;
}
.read-next-feed  .post-card-image-container{
  float: left;
  height: 100%;
  padding-right: 1em;
}
.read-next-feed .post-card-excerpt{
  padding-top: 2em;
}
.read-next-feed  .post-card-image-container img{
  max-height: 10em;
  width: auto;
  object-fit: contain;
  float: left;
}
